import * as React from 'react';
import { TCollectionColumns } from '../../../../common/base-collection';
import { FeaturedCollectionStore } from '../../../lib/dist/store/FeaturedCollectionStore';
import { IIdentifiable } from '../../../../common/base-model';
import { observable, action, Lambda, observe, toJS, IObservableArray } from 'mobx';
import { observer } from 'mobx-react';
import { TableHeaders } from './TableHeaders';
import { TableBody } from './TableBody';
import { TablePagination } from './TablePagination';
import { UserPrefs } from '../../../lib/dist/store/UserPrefs';
import { Dropdown } from './Dropdown';
import * as classnames from 'classnames';

// --

export type TTableModifier = 'divider' | 'striped' | 'hover' | 'small' | 'large';

export interface ITableProps<A, I extends IIdentifiable> extends React.HTMLAttributes<HTMLTableElement> {
    readonly tableFitWidth?: boolean;
    readonly tableFitWidthToggler?: boolean;
    readonly tableModifiers?: TTableModifier[];

    readonly columns?: TCollectionColumns<A>;
    readonly collection: FeaturedCollectionStore<any, I & { meta: any }, A, any>;

    // headers
    readonly headerClasses?: Partial<Record<keyof A, string>>;
    readonly customHeaders?: React.ReactElement<HTMLTableRowElement>[];
    readonly selectionHeader?: boolean;

    // user prefs
    readonly userPrefs?: UserPrefs;

    // pagination
    readonly showPagination?: 'both' | 'top' | 'bottom' | false;
    readonly topPaginationContent?: React.ReactElement;
    readonly bottomPaginationContent?: React.ReactElement;

    // body
    readonly nonIdealStateContent?: React.ReactElement | null | true;
    rowRenderer(item: I, fields: TCollectionColumns<A>): React.ReactElement;

    // misc handler
    onAfterColumnResize?(columnWidths: { [key: string]: number }): void;
    onTableFitWidtChange?(v: boolean): void;
}

/**
 * Paginator container
 * @param props 
 */
const TablePaginationContainer: React.SFC<{ pagination: React.ReactElement } & { position: 'top' | 'bottom' }> = props => {

    return (
        <div className={classnames('uk-flex uk-flex-row uk-flex-middle uk-flex-wrap uk-flex-wrap-around uk-margin', `ui-table-pagination-${props.position}`)}>
            <div className='uk-flex-auto'>
                {props.children}
            </div>
            <div className='uk-flex-auto'>
                {props.pagination}
            </div>
        </div>
    )
}

/**
 * Table class
 */
@observer
export class Table<A, I extends IIdentifiable> extends React.Component<ITableProps<A, I>> {

    public static className = 'ui-table';

    public static defaultProps: Partial<ITableProps<any, any>> = {
        tableFitWidth: false,
        tableFitWidthToggler: true,
        headerClasses: {},
        customHeaders: [],
        selectionHeader: false,
        nonIdealStateContent: true,
        tableModifiers: ['small', 'divider', 'hover'],
        showPagination: 'both',
        topPaginationContent: null,
        bottomPaginationContent: null,
    }

    @observable protected tableFitWidth: boolean = false;
    protected modifiers: IObservableArray<TTableModifier>;
    protected disposers: Lambda[] = [];

    public constructor(props: ITableProps<A, I>) {
        super(props);
        this.tableFitWidth = props.tableFitWidth;
        this.modifiers = observable(props.tableModifiers);
    }

    public componentDidMount() {
        if (this.props.userPrefs) {
            const { props } = this;

            this.disposers.push(observe(props.collection.features.fields, change => {
                props.userPrefs.setPath([props.collection.name, 'fields'], toJS(props.collection.features.fields), true);
            }))

            this.disposers.push(observe(props.collection.features, 'sortDirection', change => {
                if (change.type === 'update') props.userPrefs.setPath([props.collection.name, 'sortDirection'], change.newValue, true);
            }))

            this.disposers.push(observe(props.collection.features, 'sortField', change => {
                if (change.type === 'update') props.userPrefs.setPath([props.collection.name, 'sortField'], change.newValue, true);
            }))

            this.disposers.push(observe(props.collection.features, 'pageSize', change => {
                if (change.type === 'update') props.userPrefs.setPath([props.collection.name, 'pageSize'], change.newValue, true);
            }))
        }
    }

    public componentWillUnmount() {
        this.disposers.forEach(d => d());
    }

    @action protected toggleFitWidth = (ev: React.MouseEvent) => {
        ev.preventDefault();
        this.tableFitWidth = !this.tableFitWidth;

        const { props } = this;

        if (props.userPrefs) {
            props.userPrefs.setPath([props.collection.name, 'tableFitWidth'], this.tableFitWidth, true);
        }

        props.onTableFitWidtChange && props.onTableFitWidtChange(this.tableFitWidth);
    }

    @action protected toggleModifier = (d: TTableModifier, ev?: React.MouseEvent) => {
        ev && ev.preventDefault();

        if (this.modifiers.includes(d)) {
            this.modifiers.remove(d);
        }
        else {
            this.modifiers.push(d);
            if (d === 'small') this.modifiers.remove('large');
            else if (d === 'large') this.modifiers.remove('small');
        }

        if (this.props.userPrefs) {
            this.props.userPrefs.setPath([this.props.collection.name, 'modifiers'], toJS(this.modifiers));
        }
    }

    protected onAfterColumnResize = (columnWidths: { [key: string]: number }) => {
        if (!this.props.onAfterColumnResize && !this.props.userPrefs) return;

        const { props } = this;

        // update collection column widths
        props.collection.columns.forEach(col => {
            if (columnWidths[col.fieldName as any]) col.width = columnWidths[col.fieldName as any];
        })

        // update user prefs store
        props.userPrefs && props.userPrefs.setPath([props.collection.name, 'columnWidths'], columnWidths, true);

        // call handler
        props.onAfterColumnResize && this.props.onAfterColumnResize(columnWidths);
    }

    protected onToggleSelectAllRows = () => {
        if (this.props.collection.isSelectedAll) this.props.collection.unselectAll();
        else this.props.collection.selectAll();
    }

    protected onSort = (fieldName: keyof A) => {
        const { props } = this;

        props.collection.setSort(fieldName);
    }

    protected onHideField = (fieldName: keyof A) => {
        this.props.collection.removeField(fieldName);
    }

    protected onSwapFields = (src: keyof A, dst: keyof A) => {
        this.props.collection.swapFields(src, dst);
    }

    protected onPageChange = (index: number) => {
        this.props.collection.setFeature('pageIndex', index);
    }

    public render() {

        const { props } = this;
        let tablePagination = props.showPagination ? (<TablePagination features={props.collection.features} onPageChange={this.onPageChange} />) : null;

        return (
            <div className='ui-table-container'>

                {props.showPagination === 'both' || props.showPagination === 'top' ? <TablePaginationContainer pagination={tablePagination} position='top'>{props.topPaginationContent}</TablePaginationContainer> : null}

                <div className="ui-table-toolbar">
                    <Dropdown >
                        <a><i className='fas fa-cog' title='Show table options' /></a>

                        <ul className='uk-nav uk-dropdown-nav uk-pading-small'>
                            <li className='uk-nav-header'>Table modifiers</li>

                            {props.tableFitWidthToggler ? (
                                <li className={this.tableFitWidth ? 'uk-active' : null}><a href='#' onClick={this.toggleFitWidth} ><i className='fas fa-arrows-alt-h ' />Fit width</a></li>
                            ) : null}

                            <li className={this.modifiers.includes('striped') ? 'uk-active' : null}><a href='#' onClick={this.toggleModifier.bind(null, 'striped')}><i className='fas fa-bars'/>Toggle stripes</a></li>
                            <li className={this.modifiers.includes('divider') ? 'uk-active' : null}><a href='#' onClick={this.toggleModifier.bind(null, 'divider')}><i className='fas fa-divide'/>Toggle divider</a></li>
                            <li className={this.modifiers.includes('hover') ? 'uk-active' : null}><a href='#' onClick={this.toggleModifier.bind(null, 'hover')}><i className='fas fa-mouse-pointer'/>Toggle hover</a></li>
                            <li className={this.modifiers.includes('small') ? 'uk-active' : null}><a href='#' onClick={this.toggleModifier.bind(null, 'small')}><i className='fas fa-compress'/>Small table</a></li>
                            <li className={this.modifiers.includes('large') ? 'uk-active' : null}><a href='#' onClick={this.toggleModifier.bind(null, 'large')}><i className='fas fa-expand'/>Large table</a></li>
                        </ul>
                    </Dropdown>
                </div>

                <div className={this.tableFitWidth ? null : 'uk-overflow-auto ui-table-overflow'}>
                    <table className={classnames(
                        Table.className,
                        this.modifiers.map(d => `uk-table-${d}`),
                        props.className
                    )}
                    >
                        <TableHeaders
                            columns={props.columns || props.collection.orderedColumns}
                            features={props.collection.features}
                            headerClasses={props.headerClasses}
                            onHideField={this.onHideField}
                            onSwapFields={this.onSwapFields}
                            onSort={this.onSort}
                            onAfterColumnResize={this.onAfterColumnResize}
                            onToggleSelectAllRows={this.onToggleSelectAllRows}
                            tableFitWidth={this.tableFitWidth}
                            allRowsSelected={props.collection.isSelectedAll}
                            customHeaders={props.customHeaders}
                            selectionHeader={props.selectionHeader}
                        />

                        <TableBody
                            items={props.collection.items}
                            columns={props.columns || props.collection.orderedColumns}
                            customHeadersLength={props.customHeaders.length + (props.selectionHeader ? 1 : 0)}
                            features={props.collection.features}
                            rowRenderer={props.rowRenderer}
                            nonIdealStateContent={props.nonIdealStateContent}
                        />
                    </table>
                </div>

                {props.showPagination === 'both' || props.showPagination === 'bottom' ? <TablePaginationContainer pagination={tablePagination} position='bottom'>{props.bottomPaginationContent}</TablePaginationContainer> : null}

            </div>
        )
    }
}