import * as React from 'react';
import * as classnames from 'classnames';

// --

export type TImageSize = 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
export type TImageAlign = 'top' | 'bottom' | 'middle';
export type TImageFloat = 'left' | 'right';

// --

export interface IImageBaseProps {
    readonly imageDisabled?: boolean;
    readonly imageAligned?: TImageAlign;
    readonly imageRounded?: boolean;
    readonly imageBordered?: boolean;
    readonly imageCircular?: boolean;
    readonly imageFluid?: boolean;
    readonly imageAvatar?: boolean;
    readonly imageFloated?: TImageFloat;
    readonly imageCentered?: boolean;
    readonly imageSize?: TImageSize;
}

export interface IImageProps extends React.ImgHTMLAttributes<HTMLImageElement>, IImageBaseProps {
    readonly imageInline?: boolean;
}

/**
 * Image
 */
export class Image extends React.Component<IImageProps, {}> {

    static uiPrefix: string = 'ui-image';

    static defaultProps: Partial<IImageProps> = {

    }

    static createElement(props: IImageProps) {
        return React.createElement(Image, props);
    }

    render() {

        const { children, imageAligned, imageAvatar, imageBordered, imageCentered, imageCircular, imageDisabled, imageFloated,
            imageFluid, imageInline, imageRounded, imageSize, className, ...rest } = this.props;

        return React.createElement('img', {
            className: classnames(
                Image.uiPrefix,
                imageAligned && `${Image.uiPrefix}-aligned-${imageAligned}`,
                imageAvatar && `${Image.uiPrefix}-avatar`,
                imageBordered && `${Image.uiPrefix}-bordered`,
                imageCentered && `${Image.uiPrefix}-centered`,
                imageCircular && `${Image.uiPrefix}-circular`,
                imageDisabled && `${Image.uiPrefix}-disabled`,
                imageFloated && `${Image.uiPrefix}-${imageFloated}`,
                imageFluid && `${Image.uiPrefix}-fluid`,
                imageInline && `${Image.uiPrefix}-inline`,
                imageRounded && `${Image.uiPrefix}-rounded`,
                imageSize && `${Image.uiPrefix}-${imageSize}`,
                className
            ),
            ...rest
        });

    }
}
