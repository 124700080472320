import * as React from 'react';
import { Appbar, Icon } from '../../../uilib';
import { observer } from 'mobx-react';
import { Api } from '../../../lib';
import classnames from 'classnames';

// --

export interface IAppBarProps {
    router?: any;
    api: Api;
}

/**
 * AppBar
 */
@observer
export class AppBar extends React.Component<IAppBarProps> {

    logout = () => {
        const { router, api } = this.props;
        router && router.stop();
        api.deauthenticate();
        api.disconnectSocket();
        window.location.assign('/auth/login?ref=' + window.location.pathname);
    }

    renderUserMenuItems() {
        return ([
            <li key='logout'>
                <a onClick={this.logout}>Logout</a>
            </li>
        ])
    }

    navigate = (url: string, app: string, route: string, ev: React.MouseEvent) => {
        ev.preventDefault();
        if (app && route && location.pathname === app) {
            this.props.router.navigate(route);
        }
        else this.navigateExternal(url);
    }

    navigateExternal = (url: string) => {
        const { router, api } = this.props;
        router && router.stop();
        api.disconnectSocket();
        window.location.replace(url);
    }

    renderOffcanvas() {

        const getActiveAppClass = (s: string) => {
            return location.pathname.indexOf(`/${s}`) === 0 ? 'uk-active' : '';
        }

        const { api } = this.props;

        return (
            <ul className='uk-nav uk-nav-default'>
                <li className='uk-nav-header'>CARBON</li>

                <li className='uk-nav-divider' />

                <li className={getActiveAppClass('home')}><a href='#' onClick={this.navigate.bind(null, '/home', '', '')} data-offcanvas='close'><Icon iconType='home' className='uk-margin-small-right' data-offcanvas='close'/>Home</a></li>


                <li className={getActiveAppClass('questionnaires')} hidden={!api.hasRole('author', 'questionnaire')}>
                    <a href='#' onClick={this.navigate.bind(null, '/questionnaires', '/questionnaires', 'questionnaires')} data-offcanvas='close'>
                        <Icon iconType='question' className='uk-margin-small-right' data-offcanvas='close' />
                        Questionnaires
                    </a>

                    <ul className='uk-nav-sub'>
                        <li><a href='#' onClick={this.navigate.bind(null, '/questionnaires#?a=create', '/questionnaires', '')} data-offcanvas='close'><Icon iconType='plus' className='uk-margin-small-right' data-offcanvas='close' /> New questionnaire</a></li>
                    </ul>
                </li>

                <li className={classnames('uk-parent', getActiveAppClass('invitations'))} hidden={!api.hasRole('publisher', 'questionnaire')}>
                    <a href='#' onClick={this.navigate.bind(null, '/invitations#/is', '/invitations', 'invitations')} data-offcanvas='close'><Icon iconType='forward' className='uk-margin-small-right' data-offcanvas='close'/>Invitations</a>
                    <ul className='uk-nav-sub'>
                        <li><a href='#' onClick={this.navigate.bind(null, '/invitations#/new/1',  '/invitations', 'newInvitation.step1')} data-offcanvas='close'><Icon iconType='plus' className='uk-margin-small-right' data-offcanvas='close' /> New invitation</a></li>
                        <li><a href='#' onClick={this.navigate.bind(null, '/invitations#/rs', '/invitations', 'responses')} data-offcanvas='close'><Icon iconType='commenting' className='uk-margin-small-right' data-offcanvas='close' /> Responses</a></li>
                        <li><a href='#' onClick={this.navigate.bind(null, '/invitations#/ps', '/invitations', 'projects')} data-offcanvas='close'><Icon iconType='world' className='uk-margin-small-right' data-offcanvas='close' /> Projects</a></li>
                    </ul>
                </li>

                <li className={getActiveAppClass('files')} hidden={!api.hasRole('author', 'questionnaire')} data-offcanvas='close'>
                    <a href='#' onClick={this.navigate.bind(null, '/files', '', '')} data-offcanvas='close'>
                        <Icon iconType='album' className='uk-margin-small-right' data-offcanvas='close' />
                        Files
                    </a>
                </li>
                <li className={classnames('uk-parent', getActiveAppClass('admin'))} hidden={!api.hasScope('admin')}>
                    <a href='#' onClick={this.navigate.bind(null, '/admin', '', '')} data-offcanvas='close'><Icon iconType='cog' className='uk-margin-small-right' data-offcanvas='close' />Administration</a>
                    <ul className='uk-nav-sub'>
                        <li hidden={!api.hasRole('users', 'admin')}><a href='#' onClick={this.navigate.bind(null, '/admin#/users', '/admin', 'users')} data-offcanvas='close'>Users</a></li>
                        <li hidden={!api.hasRole('groups', 'admin')}><a href='#' onClick={this.navigate.bind(null, '/admin#/groups', '/admin', 'groups')} data-offcanvas='close'>Groups</a></li>
                        {/*<li hidden={!api.hasScope('admin')}><a href='/admin#/languages'>Languages</a></li>*/}
                    </ul>
                </li>
            </ul>
        )
    }

    render() {

        const { socket } = this.props.api;
        const { fn, ln, uid } = socket.authToken;

        const userName = `${fn} ${ln}`;

        return (
            <Appbar
                appbarAppName='Carbon'
                appbarUserMenuItems={this.renderUserMenuItems()}
                appbarAvatarSrc={`/avatar/${uid}`}
                appbarUserName={userName}
                offcanvasContent={this.renderOffcanvas()}
            />
        )
    }
}