import * as React from 'react';
import { GacForm } from './GacForm';

/**
 * Layout components
 */
export const Layout: React.SFC<{}> = () => {

    return (
        <div className='uk-background-default' id='viewport'>
            <div className='uk-position-small uk-position-center uk-background-muted' id='gac-container'>
                <h2 className='uk-heading'>Please enter your access code:</h2>

                <GacForm />
            </div>
        </div>
    )
}