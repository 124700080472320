import * as React from 'react';
import { Dropdown } from './Dropdown';
import { Fa } from './Fa';
import * as classnames from 'classnames';

// --

export interface IPageSizeDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly pageSize: number;
    readonly pageSizeValues?: number[];
    readonly pageSizeButtonClasses?: any;
    readonly pageSizeButtonIcon?: string;
    readonly pageSizeButtonLabel?: string;
    readonly pageSizeHeader?: string;

    onPageSizeChange(value: number): void;
}

/**
 * PageSizeDropdown
 */
export class PageSizeDropdown extends React.Component<IPageSizeDropdownProps> {

    constructor(props: IPageSizeDropdownProps) {
        super(props);
    }

    static defaultProps: Partial<IPageSizeDropdownProps> = {
        pageSizeValues: [15, 25, 50, 100],
        pageSizeButtonClasses: 'uk-button-small uk-button-default',
        pageSizeButtonIcon: 'list',
        pageSizeButtonLabel: 'Items',
        pageSizeHeader: 'Page size'
    }

    render() {

        const { pageSize, pageSizeValues, pageSizeButtonClasses, pageSizeButtonIcon, pageSizeButtonLabel, pageSizeHeader, onPageSizeChange, ...rest } = this.props;

        return (
            <Dropdown {...rest}>
                <button className={classnames('uk-button', pageSizeButtonClasses)}>
                    <Fa faIcon={pageSizeButtonIcon} />  {pageSizeButtonLabel}
                </button>

                <ul className='uk-nav uk-dropdown-nav'>
                    {pageSizeHeader && <li className='uk-nav-header'>{pageSizeHeader}</li>}

                    {pageSizeValues.map(v => {
                        return (<li key={v} className={v === pageSize ? 'uk-active' : null}><a onClick={onPageSizeChange.bind(null, v)} >{v} items</a></li>)
                    })}
                </ul>
            </Dropdown>
        )
    }
}