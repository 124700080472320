import * as React from 'react';
import { ILoginFormProps, LoginForm } from './LoginForm';
import * as Bluebird from 'bluebird';

/******************************************************************************************************* */
// LoginScreen
/******************************************************************************************************* */

export interface ILoginScreenProps extends ILoginFormProps {
    readonly applicationName?: string;
    onAuthenticating?(promise: Bluebird<any>): void;
}

/**
 * LoginScreen
 */
export class LoginScreen extends React.Component<ILoginScreenProps> {

    static defaultProps: Partial<ILoginScreenProps> = {
        applicationName: 'CARBON'
    }

    render() {

        const { applicationName, ...rest } = this.props;

        return (
            <div className='uk-dark uk-background-muted'>
                <div className='uk-flex uk-flex-right'>
                    <div className='uk-flex uk-flex-middle uk-padding uk-visible@s'>
                        <div>
                            <h3 className='uk-heading-primary'>{applicationName}</h3>
                        </div>
                    </div>

                    <div className='uk-background-secondary uk-padding uk-width-1-2@m uk-height-1-1 uk-flex uk-flex-middle'>
                        <LoginForm {...rest} />
                    </div>
                </div>
            </div>
        )
    }
}