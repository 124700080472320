import * as Bluebird from 'bluebird';

// --

const rootUrl = location.origin;
const url = `${rootUrl}/validate_gac`;
const headers = new Headers();

headers.set('Content-Type', 'application/json');

/**
 * GAC validation api call
 * @param code 
 */
export const validateCode: (code: string) => Bluebird<{ redirectUrl?: string; error?: string }> = function validateCode(code) {

    return new Bluebird((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers,
            body: JSON.stringify({ code })
        })
            .then(data => {
                resolve(data.json());
            })
            .catch(reject);
    })
}