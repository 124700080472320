import * as React from 'react';
import * as Bluebird from 'bluebird';
import * as classnames from 'classnames';

/******************************************************************************************************* */
// LoginForm
/******************************************************************************************************* */

export interface ILoginFormProps {
    readonly usernameLabel?: string;
    readonly passwordLabel?: string;
    readonly authenticate: (username: string, password: string) => Bluebird<string>;
    onAuthenticating?(promise: Bluebird<any>): void;
}

export interface ILoginFormState {
    username: string;
    password: string;
    busy: boolean;
    errorMsg: string;
}

/**
 * LoginForm
 */
export class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {

    state: ILoginFormState;
    __mounted: boolean = false;

    static defaultProps: Partial<ILoginFormProps> = {
        usernameLabel: 'Email:',
        passwordLabel: 'Password:'
    }

    constructor(props: ILoginFormProps) {
        super(props);
        this.state = { username: '', password: '', busy: false, errorMsg: '' };
        this.onLogin = this.onLogin.bind(this);
    }

    componentDidMount() {
        this.__mounted = true;
    }

    componentWillUnmount() {
        this.__mounted = false;
    }

    onInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const change: Record<keyof ILoginFormState, any> = { errorMsg: null } as Pick<ILoginFormState, keyof ILoginFormState>;
        change[ev.target.name as keyof ILoginFormState] = ev.target.value;
        this.setState(change);
    }

    onLogin(ev: React.MouseEvent<HTMLButtonElement>) {
        ev.preventDefault();
        ev.stopPropagation();

        this.setState({ busy: true }, () => {

            const promise = this.props.authenticate(this.state.username, this.state.password);
            typeof this.props.onAuthenticating === 'function' && this.props.onAuthenticating(promise);

            promise
                .then(data => {
                    this.__mounted && this.setState({ busy: false })
                })
                .catch(err => {
                    this.__mounted && this.setState({ busy: false, errorMsg: err.message || 'Login failed' });
                })
        });
    }

    render() {

        const { props, onInputChange, onLogin } = this;
        const { username, password, busy, errorMsg } = this.state;
        const disabledLogin = !username || !password;

        return (
            <form className='uk-form uk-grid uk-grid-small' noValidate>
                <h3 className='uk-heading-primary uk-hidden@s uk-margin-medium'>CARBON</h3>

                <div className='uk-margin-small uk-width-3-4@m uk-width-1-1@s'>
                    <label className='uk-form-label uk-light'>{props.usernameLabel}</label>
                    <input type='email' className={classnames('uk-input', errorMsg && 'uk-form-danger')} value={username} onChange={onInputChange} name='username' />
                </div>
                <div className='uk-margin-small uk-width-3-4@m uk-width-1-1@s'>
                    <label className='uk-form-label uk-light'>{props.passwordLabel}</label>
                    <input type='password' className={classnames('uk-input', errorMsg && 'uk-form-danger')} value={password} onChange={onInputChange} name='password' />
                </div>
                <div className='uk-margin uk-width-1-1'>
                    <button className='uk-button uk-button-primary' disabled={disabledLogin || busy} onClick={onLogin}>Login</button>
                </div>
            </form>
        )
    }
}