import * as React from 'react';
import { Modal } from '../../../uilib';

// --

export interface IErrorBoundaryModalState {
    readonly error: Error;
    readonly info: any;
}

/**
 * ErrorBoundaryModal
 */
export class ErrorBoundaryModal extends React.Component<{}, IErrorBoundaryModalState> {

    public state: IErrorBoundaryModalState = { error: null, info: null };

    componentDidCatch(error: Error, info?: any) {
        this.setState({ error, info });
    }

    acknowledgeError = () => {
        this.setState({ error: null, info: null });
    }

    render() {

        if (this.state.error) return (
            <Modal modalOpen={Boolean(this.state.error)} modalOnClose={this.acknowledgeError} modalContainer={false} >

                <div className='uk-modal-body'>
                    <h3 className='uk-modal-title uk-text-danger'>Something went wrong</h3>
                    <p>
                        <code style={{ whiteSpace: 'normal' }}>
                            {this.state.info.componentStack}
                        </code>
                    </p>
                </div>

                <div className='uk-modal-footer uk-text-right'>
                    <button className='uk-button uk-button-default uk-modal-close uk-button-small' type='button' onClick={this.acknowledgeError}>OK</button>
                </div>
            </Modal >
        )
        else return this.props.children as React.ReactElement<any>;
    }
}

