import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classnames from 'classnames';
import { Icon } from './Icon';
import keyCodes from '../util/keycodes';

/******************************************************************************************************* */
// Loadbar
/******************************************************************************************************* */

export type TModalClosePosition = 'default' | 'outside';

export interface IModalProps extends React.LiHTMLAttributes<HTMLDivElement> {
    readonly modalOpen: boolean;
    readonly modalAnimation?: boolean;
    readonly modalCloseOnEsc?: boolean;
    readonly modalContainer?: boolean;
    readonly modalFull?: boolean;
    readonly modalCenter?: boolean;
    readonly modalClosePosition?: TModalClosePosition;
    readonly modalMultipleModals?: boolean;
    readonly modalOnClose?: (ev?: React.MouseEvent | KeyboardEvent) => void;
}

export interface IModalState {
    transitioning: boolean;
}


/**
 * Modal
 */
export class Modal extends React.Component<IModalProps, IModalState> {

    constructor(props: IModalProps) {
        super(props);
        this.state = { transitioning: false };
    }

    static uiPrefix: string = 'uk-modal';

    static defaultProps: Partial<IModalProps> = {
        modalCloseOnEsc: true,
        modalAnimation: true,
        modalContainer: true,
        modalClosePosition: 'default',
        modalMultipleModals: false
    }

    static createElement(props: IModalProps, ...children: React.ReactNode[]) {
        return React.createElement(Modal, props, ...children);
    }

    componentWillReceiveProps(props: IModalProps) {
        if (props.modalOpen !== this.props.modalOpen) {
            props.modalAnimation && this.onAnimationEnd();

            if (props.modalMultipleModals) return;

            if (props.modalOpen) {
                document.documentElement.classList.add('uk-modal-page');
                if (document.body.style.overflow !== 'hide') {
                    document.body.style.overflow = 'hide';
                }
            }
            else if (!props.modalAnimation) {
                document.documentElement.classList.remove('uk-modal-page');
                document.body.style.overflowY = null;
            }
        }
    }

    componentDidUpdate(props: IModalProps, state: IModalState) {

        if (props.modalOpen !== this.props.modalOpen) {
            if (this.props.modalOnClose && this.props.modalCloseOnEsc) {
                if (this.props.modalOpen) window.addEventListener('keyup', this.onWndKeyUp);
                else window.removeEventListener('keyup', this.onWndKeyUp);
            }
        }
        if (props.modalMultipleModals) return;
        if (this.props.modalAnimation && !this.props.modalOpen && !this.state.transitioning && state.transitioning) {
            document.documentElement.classList.remove('uk-modal-page');
            document.body.style.overflowY = null;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.onWndKeyUp);
    }

    onWndKeyUp = (ev: KeyboardEvent) => {
        ev.keyCode === keyCodes.escape && this.props.modalOnClose(ev);
    }

    onAnimationEnd = () => {
        this.setState({ transitioning: !this.state.transitioning });
    }

    render() {

        const { className, modalCloseOnEsc, modalOnClose, modalOpen, modalAnimation, modalCenter, modalContainer, modalClosePosition, modalFull, modalMultipleModals, children, ...rest } = this.props;
        const { transitioning } = this.state;
        const { onAnimationEnd } = this;

        if (!modalOpen && (!modalAnimation || (modalAnimation && !transitioning))) return null;

        const modal = React.createElement('div', {
            className: classnames(
                Modal.uiPrefix,
                modalOpen && 'uk-open',
                modalContainer && 'uk-modal-container',
                modalFull && 'uk-modal-full',
                modalCenter && 'uk-margin-auto-vertical',
                modalAnimation && transitioning ? modalOpen ? `uk-animation-fade uk-animation-fast` : `uk-animation-fade uk-animation-fast uk-animation-reverse` : null,
                className
            ),
            style: modalOpen || transitioning ? { display: 'block' } : null,
            ref: 'container',
            ...rest
        },

            React.createElement('div', {
                className: classnames(
                    'uk-modal-dialog',
                    modalAnimation && transitioning ? modalOpen ? 'uk-animation-slide-top uk-animation-fast' : 'uk-animation-slide-top uk-animation-reverse' : null,
                ),
                ref: 'dialog',
                onAnimationEnd
            },
                modalOnClose && Icon.createElement({ iconType: 'close', iconTagName: 'button', className: classnames('uk-close', `uk-modal-close-${modalClosePosition}`), onClick: modalOnClose }),
                children
            )
        );

        return ReactDOM.createPortal(modal, document.body);

    }
}