import * as React from 'react';
import { IBaseCollectionFeatures, TCollectionColumns } from '../../../../common/base-collection';
import { TableHeader } from './TableHeader';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

// --

export interface ITableHeadersProps<A> extends React.HTMLAttributes<HTMLTableRowElement> {
    readonly columns: TCollectionColumns<A>;
    readonly features: IBaseCollectionFeatures<A>;
    readonly headerClasses: Partial<Record<keyof A, string>>;
    readonly allRowsSelected: boolean;
    readonly tableFitWidth: boolean;
    readonly customHeaders: React.ReactElement<HTMLTableRowElement>[];
    readonly selectionHeader?: boolean;
    onSort(field: keyof A | string, direction?: 'asc' | 'desc');
    onHideField(field: keyof A);
    onSwapFields(src: keyof A, dst: keyof A): void;
    onToggleSelectAllRows?(): void;
    onAfterColumnResize(columnWidths: { [key: string]: number }): void;
}

@observer
export class TableHeaders<A> extends React.Component<Readonly<ITableHeadersProps<A>>> {

    public static defaultProps: Partial<ITableHeadersProps<any>> = {
        className: 'ui-table-headers',
        customHeaders: []
    }

    @observable public draggedFieldName: keyof A | null = null;
    protected headersRef: React.RefObject<HTMLTableRowElement>;

    public constructor(props: Readonly<ITableHeadersProps<A>>) {
        super(props);
        this.headersRef = React.createRef();
    }

    @action protected setDraggedField = (fieldName: keyof A | null) => {
        this.draggedFieldName = fieldName;
    }

    protected onAfterColumnResize = () => {
        const header = this.headersRef.current;
        const columnWidths: { [key: string]: number } = {};
        const headers = header.getElementsByClassName('ui-thead');

        for (let i = 0; i < headers.length; i++) {
            const h = headers[i]
            if (h.hasAttribute('data-header-field') && h.hasAttribute('data-header-field-width')) {
                columnWidths[h.getAttribute('data-header-field')] = parseInt(h.getAttribute('data-header-field-width'));
            }
        }

        this.props.onAfterColumnResize(columnWidths);
    }

    public render() {

        const { props, draggedFieldName, } = this;

        return (
            <thead>
                <tr className={props.className} ref={this.headersRef}>

                    {
                        props.selectionHeader && typeof props.allRowsSelected === "boolean" ? (
                            <th className='uk-table-shrink uk-text-center'>
                                <input type='checkbox' className='uk-checkbox' checked={props.allRowsSelected} onChange={props.onToggleSelectAllRows} />
                            </th>
                        )
                            : null
                    }

                    {...props.customHeaders}

                    {props.columns.map(col => (
                        <TableHeader
                            key={col.fieldName as string}
                            onSort={props.onSort}
                            onHide={props.onHideField}
                            column={col}
                            features={props.features}
                            className={props.headerClasses[col.fieldName]}
                            draggedFieldName={draggedFieldName}
                            setDraggedField={this.setDraggedField}
                            onSwapFields={props.onSwapFields}
                            onAfterColumnResize={this.onAfterColumnResize}
                        />
                    ))}
                </tr>
            </thead>
        )
    }
}