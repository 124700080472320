import { questionnaire } from '../../../../common';

function numberToLiteral(i: number): string {
    return (i >= 26 ? numberToLiteral((i / 26 >> 0) - 1) : '') + 'abcdefghijklmnopqrstuvwxyz'[i % 26 >> 0];
}

function numberToRoman(num: number): string {
    if (!+num)
        return null;
    var digits = String(+num).split(""),
        key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
            "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
            "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export function number(index: number, type?: questionnaire.TItemNumberingType) {

    switch (type) {
        case 'numeric': return index + 1;
        case 'literal': return numberToLiteral(index);
        case 'roman': return numberToRoman(index + 1);
        default: return null;
    }
}