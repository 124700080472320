import * as React from 'react';
import { IBaseCollectionFeatures, ICollectionColumn } from '../../../../common/base-collection';
import { observer } from 'mobx-react';
import * as classnames from 'classnames';
import { observable, action } from 'mobx';
import { Icon } from './Icon';

// --

export interface ITableHeaderProps<A> extends React.HTMLAttributes<HTMLTableHeaderCellElement> {
    column: ICollectionColumn<A>;
    features: IBaseCollectionFeatures<A>;
    draggedFieldName: keyof A | null;
    onSort(fieldName: keyof A): void;
    onHide(fieldName: keyof A): void;
    setDraggedField(fieldName: keyof A | null): void;
    onSwapFields(src: keyof A, dst: keyof A): void;
    onAfterColumnResize?(): void;
}

@observer
export class TableHeader<A> extends React.Component<Readonly<ITableHeaderProps<A>>> {

    public static className: string = 'ui-thead';

    public constructor(props: Readonly<ITableHeaderProps<A>>) {
        super(props);
        if (props.column.width) this.containerWidth = props.column.width;
    }

    protected onSort = (ev: React.MouseEvent<HTMLAnchorElement>) => {
        ev.preventDefault();
        this.props.onSort(this.props.column.fieldName);
    }

    @observable public dropTarget: boolean = false;
    @observable protected containerWidth: number = null;

    protected containerRef: React.RefObject<HTMLDivElement> = React.createRef();

    @action public setDropTarget(value: boolean) {
        this.dropTarget = value;
    }

    protected onHide = (ev: React.MouseEvent<HTMLAnchorElement>) => {
        ev.stopPropagation();
        ev.preventDefault();
        this.props.onHide(this.props.column.fieldName);
    }

    protected onDragStart = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.dataTransfer.dropEffect = "move";
        ev.dataTransfer.setData("text/plain", this.props.column.fieldName as string);
        this.props.setDraggedField(this.props.column.fieldName);
    }

    protected onDrag = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();
    }

    protected onDragEnd = (ev: React.DragEvent<HTMLDivElement>) => {
        this.props.setDraggedField(null);
    }

    protected onDragEnter = (ev: React.DragEvent<HTMLDivElement>) => {
        if (this.props.draggedFieldName && this.props.draggedFieldName !== this.props.column.fieldName) {
            ev.preventDefault();
            this.setDropTarget(true);
        }
    }

    protected onDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
        if (this.props.draggedFieldName && this.props.draggedFieldName !== this.props.column.fieldName) {
            ev.preventDefault();
            this.setDropTarget(true);
        }
    }

    protected onDragLeave = (ev: React.DragEvent<HTMLDivElement>) => {
        this.dropTarget && this.setDropTarget(false);
    }

    @action protected onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.onSwapFields(this.props.draggedFieldName, this.props.column.fieldName);
        this.props.setDraggedField(null);
    }

    protected onClose = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.onHide(this.props.column.fieldName);
    }

    protected onResizeStart = (ev: React.MouseEvent) => {
        ev.preventDefault();
        window.addEventListener('mousemove', this.onResize);
        window.addEventListener('mouseup', this.onResizeEnd);
    }

    protected onResizeEnd = () => {
        window.removeEventListener('mousemove', this.onResize);
        window.removeEventListener('mouseup', this.onResizeEnd);
        this.props.onAfterColumnResize();
    }

    @action public onResize = (ev: MouseEvent) => {
        const width = this.containerRef.current.getBoundingClientRect().width;
        const newWidth = width + ev.movementX;
        if ((this.props.column.minWidth && newWidth < this.props.column.minWidth) || (this.props.column.maxWidth && newWidth > this.props.column.maxWidth)) return;
        this.containerWidth = newWidth;
    }

    public componentWillUnmount() {
        window.removeEventListener('mouseup', this.onResizeEnd);
        window.removeEventListener('mousemove', this.onResize);
    }

    public render() {
        const { features, column, onSort, onHide, children, draggedFieldName, setDraggedField, onSwapFields, onAfterColumnResize, className, ...rest } = this.props;

        if (!features.fields.includes(column.fieldName)) return null;
        const isSortable = column.sortable !== false;
        const isSorted = features.sortField === column.fieldName;
        const isMovable = column.movable !== false;
        const isClosable = column.closable !== false;
        const isResizable = column.resizable !== false;
        const isDragged = isMovable && draggedFieldName && draggedFieldName === column.fieldName;

        return (
            <th {...rest}
                data-header-field={column.fieldName}
                data-header-field-width={this.containerWidth}
                className={classnames(className, TableHeader.className, draggedFieldName && draggedFieldName !== column.fieldName && TableHeader.className + '-droptarget',
                this.dropTarget && TableHeader.className + '-droptarget-active',
                isSorted && `${TableHeader.className}-sort-${features.sortDirection}`,
                isMovable && `${TableHeader.className}-move`,
                isSortable && `${TableHeader.className}-sort`,
                isClosable && `${TableHeader.className}-close`,
                isDragged && `${TableHeader.className}-dragged`,
                isResizable && `${TableHeader.className}-resizable`,
                column.centerText === true ? 'uk-text-center' : 'uk-text-left',
                column.fitContentWidth === true && 'uk-table-shrink'
            )}
                onDragOver={this.onDragOver} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop}
            >
                <div draggable={isMovable}
                    onDragStart={isMovable ? this.onDragStart : null} onDrag={isMovable ? this.onDrag : null} onDragEnd={isMovable ? this.onDragEnd : null}
                    ref={this.containerRef}
                    style={{ width: this.containerWidth, minWidth: column.minWidth || 75 }}
                >
                    {
                        column.sortable !== false ?
                            (<span className='uk-link'
                                onClick={this.onSort} draggable={false} onDragEnter={this.onDragEnter} onDragOver={this.onDragOver}>

                                {column.fieldLabel}
                            </span>)
                            : column.fieldLabel
                    }

                    {children}
                </div>

                {isClosable && !isDragged ? <Icon iconType='close' iconRatio={.8} iconTagName='a' className='uk-close-icon uk-text-danger' onClick={this.onClose} title='Hide column' /> : null}

                {isResizable ? <i className={`${TableHeader.className}-resize-handle`} onMouseDown={this.onResizeStart} title='Resize column' /> : null}

            </th>
        )
    }
}