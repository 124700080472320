import * as React from 'react';
import { SearchDropdown, ISearchDropdownProps } from './SearchDropdown';
import { Icon } from './Icon';
import * as classnames from 'classnames';

// --

export interface ISearchFieldProps extends ISearchDropdownProps {
    readonly value: string;
    readonly defaultValue?: string;
}

/**
 * SearchField
 */
export class SearchField extends React.Component<ISearchFieldProps, {}> {

    static defaultProps: Partial<ISearchFieldProps> = {
        defaultValue: ''
    }

    onClear = () => {
        this.props.onSelectValue(this.props.defaultValue);
    }

    render() {

        const { value, defaultValue, width, ...rest } = this.props;

        if (value) return (
            <div className='uk-inline'>
                <Icon iconTagName='a' iconType='close' className='uk-form-icon uk-form-icon-flip uk-form-width-large' onClick={this.onClear} />
                <input className={classnames('uk-input', width && `uk-form-width-${width}`)} readOnly={true} value={value} />
            </div>
        )
        else return <SearchDropdown width={width} {...rest} />;
    }

}