import * as React from 'react';
import * as classnames from 'classnames';
import { Icon } from './Icon';


// --

export interface ITagProps extends React.HTMLAttributes<HTMLSpanElement> {
    onClose?(): void;
    tagSize?: 'small' | 'medium' | 'large' | 'xlarge';
    readOnly?: boolean;
}

/**
 * Tag
 */
export class Tag extends React.Component<ITagProps> {

    static uiPrefix = 'ui-tag';

    static defaultProps: Partial<ITagProps> = {

    }

    onClose = () => {
        !this.props.readOnly && this.props.onClose();
    }

    render() {

        const { className, children, onClose, tagSize, readOnly } = this.props;

        return (
            <span className={classnames(Tag.uiPrefix, className, tagSize && `ui-tag-${tagSize}` )} >
                {children}
                {onClose && <Icon iconType='close' iconRatio={.8} iconTagName='a' onClick={this.onClose} className={readOnly ? 'uk-disabled' : null} />}
            </span>
        )
    }
}