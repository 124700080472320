import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Modal } from '../../../uilib';
import { TSCSocketState } from '../../../../common';
import { observer } from 'mobx-react';

// --

export interface IScConnectStateModalModalProps {
    readonly api: { socketConnected: boolean };
}

export interface IScConnectStateModalModalState {
    socketState: TSCSocketState;
}

/**
 * ScConnectStateModal
 */
@observer
export class ScConnectStateModal extends React.Component<IScConnectStateModalModalProps, IScConnectStateModalModalState> {

    state: IScConnectStateModalModalState;

    render() {

        const { socketConnected } = this.props.api;

        const modal = (
            <Modal modalOpen={!socketConnected} modalContainer={false} >

                <div className='uk-modal-body'>
                    <h3 className='uk-modal-title uk-text-warning'>Disconnected</h3>
                    <p>
                        Do not close this window, will try to reconnect automatically
                    </p>
                </div>

            </Modal>
        )

        return ReactDOM.createPortal(modal, document.body);
    }
}

