import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classnames from 'classnames';

/******************************************************************************************************* */
// AppFooter
/******************************************************************************************************* */

export interface IAppFooterProps extends React.HTMLAttributes<HTMLDivElement> {

}

/**
 * AppFooter
 */
export class AppFooter extends React.Component<IAppFooterProps> {

    static uiPrefix = 'ui-appfooter';

    render() {

        const { className, children, ...rest } = this.props;

        const footer = React.createElement('div', {
            className: classnames(AppFooter.uiPrefix, className),
            ...rest
        }, children);

        return ReactDOM.createPortal(footer, document.body);
    }
}