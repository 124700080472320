
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classnames from 'classnames';
import { childHasParentWithAttr } from '../util/dom';
const { debounce } = require('debounce-decorator');

/******************************************************************************************************* */
// Dropdown
/******************************************************************************************************* */

export interface IDropdownProps extends React.HTMLAttributes<HTMLElement> {
    dropdownClassName?: string;
    dropdownTagName?: string;
    dropdownMenuAlignment?: 'left' | 'right';
}

export interface IDropdownState {
    open: boolean;
}

/**
 * Dropdown
 */
export class Dropdown extends React.Component<IDropdownProps, IDropdownState> {

    constructor(props: IDropdownProps) {
        super(props);
        this.state = { open: false };
        this.toggleOpenState = debounce(this.toggleOpenState, 50);
    }

    static preventDropdownCloseAttr = 'data-prevent-dd-close';

    static defaultProps: IDropdownProps = {
        dropdownTagName: 'div',
        dropdownMenuAlignment: 'left'
    }

    static createElement(props: IDropdownProps, ...children: React.ReactNode[]) {
        return React.createElement(Dropdown, props, ...children);
    }

    protected __mounted: boolean = false;;

    componentDidUpdate() {
        if (this.state.open) {
            window.addEventListener('mouseup', this.toggleOpenState, true);
        }
        else window.removeEventListener('mouseup', this.toggleOpenState, true);
    }

    componentDidMount() {
        this.__mounted = true;
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.toggleOpenState, true);
        this.__mounted = false;        
    }

    toggleOpenState = (ev: MouseEvent) => {
        if (!this.__mounted) return;
        if (this.state.open && childHasParentWithAttr(ReactDOM.findDOMNode(this) as Element, ev.srcElement as Element, Dropdown.preventDropdownCloseAttr)) return;
        this.setState({ open: !this.state.open });
    }

    render() {

        const { dropdownTagName, className, dropdownClassName, dropdownMenuAlignment, children, ...rest } = this.props;
        const { open } = this.state;

        const _children = React.Children.toArray(children);
        const trigger = _children.shift() as React.ReactElement<any>;
        const style = dropdownMenuAlignment === 'right' ? { right: 0, left: 'auto' } : null;

        return React.createElement(dropdownTagName,
            {
                className,
                ...rest
            },

            React.cloneElement(trigger, {
                className: classnames(trigger.props.className, open && 'uk-open'),
                onClick: this.toggleOpenState,
                ref: 'trigger',
                'aria-expanded': open
            }),

            open && React.createElement('div', {
                className: classnames('uk-dropdown', 'uk-open', dropdownClassName),
                style,
                ref: 'menu'
            },
                ..._children
            )
        )
    }

}