import * as React from 'react';
import * as classnames from 'classnames';
import { Icon } from './Icon';

// --

export interface IPaginationProps extends React.HTMLAttributes<HTMLUListElement> {
    readonly paginationPageCount: number;
    readonly paginationPageIndex: number;
    readonly paginationMaxItems?: number;
    readonly paginationOnPageChange: (index: number) => void;
}

/**
 * Pagination
 */
export class Pagination extends React.Component<IPaginationProps> {

    static uiPrefix: string = 'uk-pagination';

    static defaultProps: Partial<IPaginationProps> = {
        paginationMaxItems: 5,
        paginationPageIndex: 0
    }

    constructor(props: IPaginationProps) {
        super(props);
        if (!(props.paginationMaxItems % 2)) throw new TypeError('paginationMaxItems must be an odd number');
    }

    static createElement(props: IPaginationProps, ...children: React.ReactNode[]) {
        return React.createElement(Pagination, props, ...children);
    }

    protected getLeftItems(paginationPageCount: number, paginationPageIndex: number, paginationMaxItems: number, paginationOnPageChange: (index: number) => void): React.ReactElement<any>[] {
        const items: React.ReactElement<any>[] = [];
        const pad = Math.floor(paginationMaxItems / 2);

        // Prev
        items.push(React.createElement('li', {
            className: paginationPageIndex === 0 ? 'uk-disabled' : null,
            onClick: paginationPageIndex === 0 ? null : paginationOnPageChange.bind(null, paginationPageIndex - 1)
        },
            React.createElement('a', {}, Icon.createElement({ iconType: 'pagination-previous' }))
        ));

        paginationPageIndex >= pad + 1 && items.push(React.createElement('li', {
            onClick: paginationOnPageChange.bind(null, 0)
        },
            React.createElement('a', {}, 1)
        ));

        paginationPageIndex > pad + 1 && items.push(React.createElement('li', {
        },
            React.createElement('span', { className: 'uk-disabled' }, '...')
        ));

        for (let i = pad; i > 0; i--) {
            paginationPageIndex - i >= 0 && items.push(React.createElement('li', {
                onClick: paginationOnPageChange.bind(null, paginationPageIndex - i)
            },
                React.createElement('a', {}, paginationPageIndex - i + 1)
            ))
        }

        return items;
    }

    protected getRightItems(paginationPageCount: number, paginationPageIndex: number, paginationMaxItems: number, paginationOnPageChange: (index: number) => void): React.ReactElement<any>[] {
        const items: React.ReactElement<any>[] = [];
        const pad = Math.floor(paginationMaxItems / 2);

        for (let i = 1; i <= pad; i++) {
            paginationPageIndex + i < paginationPageCount && items.push(React.createElement('li', {
                onClick: paginationOnPageChange.bind(null, paginationPageIndex + i)
            },
                React.createElement('a', {}, paginationPageIndex + i + 1)
            ))
        }

        paginationPageIndex < paginationPageCount - pad - 2 && items.push(React.createElement('li', {
        },
            React.createElement('span', { className: 'uk-disabled' }, '...')
        ));

        paginationPageIndex <= paginationPageCount - pad - 2 && items.push(React.createElement('li', {
            onClick: paginationOnPageChange.bind(null, paginationPageCount - 1)
        },
            React.createElement('a', {}, paginationPageCount)
        ));

        // Next
        items.push(React.createElement('li', {
            className: paginationPageIndex === paginationPageCount - 1 ? 'uk-disabled' : null,
            onClick: paginationPageIndex === paginationPageCount - 1 ? null : paginationOnPageChange.bind(null, paginationPageIndex + 1)
        },
            React.createElement('a', {}, Icon.createElement({ iconType: 'pagination-next' }))
        ));

        return items;
    }

    render() {

        const { className, paginationPageCount, paginationPageIndex, paginationMaxItems, paginationOnPageChange, children, ...rest } = this.props;


        return React.createElement('ul', {
            className: classnames(
                'uk-flex uk-flex-middle',
                Pagination.uiPrefix,
                className,
            ),
            ...rest
        },
            ...this.getLeftItems(paginationPageCount, paginationPageIndex, paginationMaxItems, paginationOnPageChange),
            React.createElement('li', {
                className: 'uk-active'
            },
                React.createElement('span', {}, paginationPageIndex + 1)
            ),
            ...this.getRightItems(paginationPageCount, paginationPageIndex, paginationMaxItems, paginationOnPageChange),

            children
        );
    }
}