
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classnames from 'classnames';
import { Icon, TIconType } from './Icon';
import { childHasParentWithAttr } from '../util/dom';
const { debounce } = require('debounce-decorator');


/******************************************************************************************************* */
// SearchDropdown
/******************************************************************************************************* */

export interface ISearchDropdownProps extends React.HTMLAttributes<HTMLElement> {
    term: string;
    onSearch(term: string): void;
    onSelectValue(value: string): void;
    searchClassName?: string;
    formClassName?: string;
    width?: 'large' | 'medium' | 'small';
    placeholder?: string;
    dropdownClassName?: string;
    busy?: boolean;
    icon?: TIconType;
    emptyText?: string;
    dropdownMenuAlignment?: 'left' | 'right';
    dropdownMenuFluid?: boolean;
    items: { label: React.ReactChild, value: string }[];
}

export interface ISearchDropdownState {
    open: boolean;
}

/**
 * SearchDropdown
 */
export class SearchDropdown extends React.Component<ISearchDropdownProps, ISearchDropdownState> {

    static uiPrefix = 'ui-searchdropdown'
    static preventDropdownCloseAttr = 'data-prevent-dd-close';

    constructor(props: ISearchDropdownProps) {
        super(props);
        this.state = { open: false };
        this.deactivateOpenState = debounce(this.deactivateOpenState, 50);
    }

    static preventSearchDropdownCloseAttr = 'data-prevent-dd-close';

    static defaultProps: Partial<ISearchDropdownProps> = {
        dropdownMenuAlignment: 'left',
        icon: 'search'
    }

    static createElement(props: ISearchDropdownProps, ...children: React.ReactNode[]) {
        return React.createElement(SearchDropdown, props, ...children);
    }

    protected __mounted: boolean = false;;

    componentDidUpdate() {
        if (this.state.open) {
            window.addEventListener('mouseup', this.deactivateOpenState, true);
        }
        else window.removeEventListener('mouseup', this.deactivateOpenState, true);
    }

    componentDidMount() {
        this.__mounted = true;
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.deactivateOpenState, true);
        this.__mounted = false;
    }

    deactivateOpenState = (ev: Event) => {
        if (this.refs['input'] === ev.target || !this.__mounted) return;
        if (this.state.open && (childHasParentWithAttr(ReactDOM.findDOMNode(this) as Element, ev.srcElement as Element, SearchDropdown.preventDropdownCloseAttr) || ev.srcElement === ReactDOM.findDOMNode(this.refs['input']))) return;
        this.setState({ open: false });
    }

    activateOpenState = (ev: React.FocusEvent<HTMLInputElement>) => {
        if (!this.__mounted) return;
        this.setState({ open: true });
    }

    onSearch = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSearch(ev.target.value);
    }

    render() {

        const { className, searchClassName, formClassName, dropdownClassName, dropdownMenuAlignment, dropdownMenuFluid, width, onSearch, items, onSelectValue, placeholder, emptyText, term, busy, icon, children, ...rest } = this.props;
        const { open } = this.state;

        const style = dropdownMenuAlignment === 'right' ? { right: 0, left: 'auto' } : null;

        return (
            <div className={classnames(SearchDropdown.uiPrefix, className)} {...rest}>
                <div className={classnames('uk-form uk-inline', formClassName)}>
                    <Icon iconType={busy ? 'spinner' : icon} className={classnames('uk-form-icon', busy && 'uk-spinner')} />
                    <input className={classnames('uk-input', this.props.searchClassName, width && `uk-form-width-${width}`)}
                        onFocus={this.activateOpenState}
                        value={term}
                        onChange={this.onSearch}
                        placeholder={placeholder}
                        ref='input' />
                </div>
                {
                    open ?
                        <div className={classnames('uk-dropdown', 'uk-open', dropdownClassName, dropdownMenuFluid && 'uk-width-1-1')} style={style} ref='menu'>
                            <div className='uk-nav uk-dropdown-nav'>
                                {
                                    items.length === 0 && emptyText ? <li className={dropdownMenuFluid && 'uk-text-truncate'}><i>{emptyText}</i></li>
                                        :
                                        items.map(item => (<li key={item.value} className={dropdownMenuFluid && 'uk-text-truncate'}><a onClick={onSelectValue.bind(null, item.value)}>{item.label}</a></li>))
                                }
                            </div>
                        </div>
                        : null
                }

            </div>

        )
    }

}