import { BaseStore } from '../../../../../lib/dist/store/BaseStore';
import * as Bluebird from 'bluebird';
import { DataStore } from './DataStore';

/**
 * AppStore
 */
export class AppStore extends BaseStore<void> {

    public data: DataStore;
    protected __initialized: boolean = false;

    init() {
        this.data = new DataStore(this, { trackError: 'delegate' });
    }

    initialize() {
        return Bluebird.resolve(void 0);
    }
}