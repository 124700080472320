import * as React from 'react';
import { Modal } from '../../../uilib';
import { IError } from '../../../../common';
import { observer } from 'mobx-react';

export interface IErrorModalProps {
    setError(err: Error | IError | null): any;
    error: Error | IError;
}

/**
 * ErrorMNodal
 */
@observer
export class ErrorModal extends React.Component<IErrorModalProps> {

    acknowledgeError = () => {
        this.props.setError(null);
    }

    render() {

        const { name, message } = this.props.error;
        const open = !!(name || message);

        return (
            <Modal modalOpen={open} modalOnClose={this.acknowledgeError} modalContainer={false} >
                <div className='uk-modal-body'>
                    <h3 className='uk-modal-title uk-text-danger'>Something went wrong</h3>
                    <p>
                        {name || 'Unknown error'} :

                            <code style={{ whiteSpace: 'normal' }} className='uk-margin-left'>
                            {message}
                        </code>
                    </p>
                </div>
            </Modal>
        );

    }
}