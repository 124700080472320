// --

export const isChildElement = function isChildOf(parent: Element, child: Element): boolean {

    if (!parent || !child) return false;
    if (parent === child) return true;
    if (parent.contains) return parent.contains(child);
    let _parent = child.parentNode;

    while (_parent) {
        if (_parent === parent) return true;
        _parent = child.parentNode;
    }

    return false;
}

// --

export const isChildElementOrSelf = function isChildOf(parent: Element, child: Element): boolean {
    if (parent === child) return true;
    return isChildElement(parent, child);
}

// --

export const childHasParentWithAttr = function childHasParentWithAttr(parent: Element, child: Element, attr: string): boolean {
    if (!isChildElement(parent, child)) return false;
    let crt = child;

    while (crt) {
        if (crt.hasAttribute(attr)) return true;
        if (parent === crt) return false;
        else crt = crt.parentElement;
    }

    return false;
}