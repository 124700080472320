import * as React from 'react';
import { Loadbar } from '../../../uilib';
import { observer } from 'mobx-react';

// --

export interface ILoaderProps {
    readonly store: { hasPendingAsyncRequests: boolean; };
}

/**
 * Loader
 */
@observer
export class Loader extends React.Component<ILoaderProps> {

    render() {

        return (
            <Loadbar loadbarLoading={this.props.store.hasPendingAsyncRequests} />
        )
    }
}