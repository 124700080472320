import * as React from 'react';
import { TCollectionColumns } from '../../../../common/base-collection';
import { IIdentifiable } from '../../../../common/base-model';
import { observer } from 'mobx-react';

// --

export interface ITableRowProps<A, I> extends React.HTMLAttributes<HTMLTableRowElement> {
    item: I;
    columns: TCollectionColumns<A>;
    rowRenderer(item: I, columns: TCollectionColumns<A>): React.ReactElement<HTMLTableRowElement>;
}

/**
 * Table row class
 */
@observer
export class TableRow<A, I extends IIdentifiable> extends React.Component<Readonly<ITableRowProps<A, I>>> {

    public render() {
        const { props } = this;
        return this.props.rowRenderer(props.item, props.columns);
    }
}