import * as React from 'react';
import { AppStore } from './store';
import { AppContext } from './context';
import { ErrorBoundaryModal, ErrorModal, Loader } from '../../../shared';
import { IError } from '../../../../common';
import { Layout } from './components/Layout';

// --

export interface IAppProps {
    store: AppStore
}

/**
 * App components
 */
export class App extends React.Component<IAppProps> {

    setError = (err: IError) => {
        this.props.store.setError(err)
    }

    render() {

        return (
            <div>
                <ErrorBoundaryModal>
                    <AppContext.Provider value={this.props}>
                        <Loader store={this.props.store.data} />
                        <Layout />
                    </AppContext.Provider>
                </ErrorBoundaryModal>

                <ErrorModal setError={this.setError} error={this.props.store.error} />
            </div>
        )
    }
}