import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Icon } from './Icon';

// --

export interface IOffCanvasProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly overlay?: boolean;
    readonly active: any;
    readonly 'data-offcanvas'?: string;
    onClose?(): void;
}

/**
 * OffCanvas
 */
export class OffCanvas extends React.Component<IOffCanvasProps> {

    static uiPrefix = 'uk-offcanvas-bar';

    static defaultProps: Partial<IOffCanvasProps> = {
        overlay: true
    }

    componentDidUpdate(prevProps: IOffCanvasProps) {
        if (!this.props.active) {
            window.document.documentElement.classList.remove('uk-offcanvas-page');
            window.document.body.classList.remove('uk-offcanvas-container');
            window.document.body.classList.remove('uk-offcanvas-overlay');
        }
        if (this.props.overlay && this.props.active) {
            window.document.documentElement.classList.add('uk-offcanvas-page');
            window.document.body.classList.add('uk-offcanvas-container');
            this.props.overlay && window.document.body.classList.add('uk-offcanvas-overlay');
        }
    }

    render() {

        const { active, children, overlay, onClose, ...props } = this.props;

        if (!active) return null;

        return ReactDOM.createPortal(
            <div className={overlay ? 'uk-offcanvas uk-offcanvas-overlay uk-open' : 'uk-offcanvas uk-open'} style={{ display: 'block' }} {...props}>
                <div className={OffCanvas.uiPrefix}>
                    {onClose && <Icon iconType='close' iconTagName='button' className='uk-offcanvas-close uk-close' onClick={onClose} />}
                    {children}
                </div>
            </div>
            , document.body
        )
    }
}