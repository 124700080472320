import * as React from 'react';
import * as classnames from 'classnames';

// --

export interface ITabsProps extends React.HTMLAttributes<HTMLUListElement> {
    tabs: { content: React.ReactNode, disabled?: boolean }[];
    tabsActiveIndex: number;
    onTabChange(index: number): void;
}

/**
 * Tabs
 */
export class Tabs extends React.Component<ITabsProps> {

    static uiPrefix = 'uk-tab';

    onTabChange = (index: number, ev: React.MouseEvent<any>) => {
        ev.preventDefault();
        ev.stopPropagation();
        this.props.onTabChange(index);
    }

    render() {

        const { className, tabs, tabsActiveIndex, onTabChange, children, ...rest } = this.props;

        return (
            <ul className={classnames(Tabs.uiPrefix, className)} {...rest}>
                {...tabs.map((t, index) => {
                    const isActive = index === tabsActiveIndex;
                    return (
                        <li
                            key={index}
                            className={classnames(isActive && 'uk-active', t.disabled && 'uk-disabled')}
                            aria-expanded={isActive}
                            onClick={!isActive && !t.disabled ? this.onTabChange.bind(null, index) : null}>
                            <a>{t.content}</a>
                        </li>
                    )
                })}
            </ul>
        )
    }

}