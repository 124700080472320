import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classnames from 'classnames';
const velocity = require('velocity-animate');


/******************************************************************************************************* */
// Loadbar
/******************************************************************************************************* */

export interface ILoadbarProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly loadbarLoading?: boolean;
    readonly loadbarAnimationDurations?: [number, number, number, number],
    readonly loadbarStageWidths?: [number, number, number],
}

/**
 * Loadbar
 */
export class Loadbar extends React.Component<ILoadbarProps, {}> {

    static uiPrefix = 'ui-loadbar';

    static defaultProps: Partial<ILoadbarProps> = {
        loadbarAnimationDurations: [1000, 5000, 10000, 100],
        loadbarStageWidths: [30, 50, 80],
    }

    protected $element: HTMLElement;

    static createElement(props: ILoadbarProps) {
        return React.createElement(Loadbar, props);
    }

    protected onAnimationBegin() {
        this.$element.style.width = '0px';
    }

    protected onAnimationEnd() {
        this.$element.style.width = '0px';
    }

    protected onStart() {
        velocity(this.$element, {
            width: `${this.props.loadbarStageWidths[0]}%`,
        }, {
                duration: this.props.loadbarAnimationDurations[0],
                queue: false,
                begin: () => {
                    this.$element.style.width = '0px';
                },
                complete: () => {
                    this.props.loadbarLoading && this.onIntermediateStage();
                }
            })
    }

    protected onIntermediateStage() {
        velocity(this.$element, {
            width: `${this.props.loadbarStageWidths[1]}%`,
        }, {
                duration: this.props.loadbarAnimationDurations[1],
                queue: false,
                begin: () => {
                },
                complete: () => {
                    this.props.loadbarLoading && this.onIndeterminateStage();
                }
            })
    }

    protected onIndeterminateStage() {
        velocity(this.$element, {
            width: `${this.props.loadbarStageWidths[2]}%`,
        }, {
                duration: this.props.loadbarAnimationDurations[2],
                queue: false,
                begin: () => {
                },
                complete: () => {
                }
            })
    }

    protected onComplete() {
        velocity(this.$element, {
            width: '100%',
        }, {
                duration: this.props.loadbarAnimationDurations[3],
                queue: false,
                begin: () => {
                    velocity(this.$element, "finish");
                },
                complete: () => {
                    this.$element.style.width = '0px';
                    this.onAnimationEnd();
                }
            })
    }

    shouldComponentUpdate(props: ILoadbarProps) {
        return props.loadbarLoading !== this.props.loadbarLoading;
    }

    componentDidMount() {
        this.$element = ReactDOM.findDOMNode(this) as HTMLElement;
        if (this.props.loadbarLoading) this.onStart();
    }

    componentWillUnmount() {
        velocity(this.$element, 'finish');
    }

    componentDidUpdate(props: ILoadbarProps) {
        if (this.props.loadbarLoading) this.onStart();
        else this.onComplete();
    }

    render() {

       const loadbar = React.createElement('div', {
            className: classnames(Loadbar.uiPrefix),
        });

        return ReactDOM.createPortal(loadbar, document.body);
    }
}