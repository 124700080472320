import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { store } from './store';
import { App } from './App';

// --

/** Bootstrap app */
function bootstrap() {
    let __mounted = false;

    return function () {
        if (__mounted) return;
        console.log('bootstrapping...');

        store
            .initialize()
            .then(() => {
                const appDomNode = document.getElementById('app');

                // mount app
                ReactDOM.render(React.createElement(App, { store }), appDomNode);
                __mounted = true;

                console.log('app mounted');
            })
            .catch(err => console.error(err));
    }
}

bootstrap()();