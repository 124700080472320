import * as React from 'react';
import * as classnames from 'classnames';
import { Fa } from './Fa';

// --

export interface IToolbarProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly toolbarFilterbox?: React.ReactElement<any> | React.ReactElement<any>[];
    readonly toolbarLeftContent?: React.ReactElement<any> | React.ReactElement<any>[];
    readonly toolbarRightContent?: React.ReactElement<any> | React.ReactElement<any>[];
    readonly hasFilter?: boolean;
    readonly showFilter: boolean;
    onToggleFilter: () => void;
}

/**
 * Toolbar
 */
export class Toolbar extends React.Component<IToolbarProps, {}> {

    render() {

        const { className, toolbarFilterbox, toolbarLeftContent, toolbarRightContent, hasFilter, showFilter, onToggleFilter } = this.props;

        return (
            <div className={classnames('ui-toolbar-wrapper', showFilter && 'filterbox', className)}>

                <div className='ui-toolbar'>

                    <div className='uk-grid uk-grid-small uk-grid-divider'>

                        {toolbarLeftContent && (<div className='uk-width-auto uk-flex uk-flex-middle'>
                            {toolbarLeftContent}
                        </div>)}

                        {toolbarFilterbox && (<div className='uk-width-auto uk-flex uk-flex-middle'>
                            <button className={classnames('uk-button uk-button-small uk-margin-small-right', showFilter ? 'uk-button-primary' : hasFilter ? 'uk-button-danger' : 'uk-button-default')} onClick={onToggleFilter}>
                                <Fa faIcon='filter' /> Filter
                            </button>
                        </div>)}

                        <div className='uk-width-expand'>
                        </div>

                        {toolbarRightContent && (<div className='uk-width-1-4@m'>
                            {toolbarRightContent}
                        </div>)}

                    </div>
                </div>

                {toolbarFilterbox && showFilter && (<div className='ui-filterbox'>{toolbarFilterbox}</div>)}
            </div>
        )
    }
}