import * as React from 'react';
import { TCollectionColumns, IBaseCollectionFeatures } from '../../../../common/base-collection';
import { Icon } from './Icon';
import { IIdentifiable } from '../../../../common/base-model';
import { observer } from 'mobx-react';
import { TableRow } from './TableRow';
import { IObservableArray } from 'mobx';

// --

export interface ITableBodyProps<A, I extends IIdentifiable> {
    readonly items: IObservableArray<I> | I[];
    readonly columns: TCollectionColumns<A>;
    readonly features: IBaseCollectionFeatures<A>;
    readonly customHeadersLength: number;
    readonly nonIdealStateContent: React.ReactElement | null | true;
    rowRenderer(item: I, fields: TCollectionColumns<A>): React.ReactElement<HTMLTableRowElement>;
}

/**
 * Table class
 */
@observer
export class TableBody<A, I extends IIdentifiable> extends React.Component<ITableBodyProps<A, I>> {

    protected renderNonIdealState() {
        const { props } = this;

        return (
            <tbody>
                <tr>
                    <td colSpan={props.customHeadersLength + props.features.fields.length} style={{ position: 'relative', height: 100 }}>
                        {React.isValidElement(props.nonIdealStateContent) ? props.nonIdealStateContent : props.nonIdealStateContent === true ? (
                            <div className='uk-position-center uk-flex uk-flex-middle uk-flex-row uk-text-warning'><Icon iconType='warning' iconRatio={1.2} className='uk-margin-small-right' />  <i>No data to display.</i></div>
                        ) : null}
                    </td>
                </tr>
            </tbody>

        )
    }

    public render() {
        const { props } = this;

        if (props.nonIdealStateContent && props.items.length === 0) return this.renderNonIdealState();

        return (
            <tbody>
                {props.items.map(item => (<TableRow key={item.id} item={item} columns={props.columns} rowRenderer={props.rowRenderer} />))}
            </tbody>
        )
    }
}