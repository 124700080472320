import * as React from 'react';
import { IApplicationContext, AppContext } from '../context';
import * as classnames from 'classnames';
import { observer } from 'mobx-react';
import { GacErrorBox } from './GacErrorBox';

/**
 * GacForm
 */
@observer
export class GacForm extends React.Component<{}> {

    context: IApplicationContext;

    static contextType = AppContext;

    onGacChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.context.store.data.setGac(ev.target.value);
    }

    onSubmit = () => {
        this.context.store.data.validate();
    }

    render() {

        const { data } = this.context.store;

        return (
            <div>
                <div className='uk-form'>
                    <input className={classnames('uk-input uk-form-large uk-align-center', data.patternValidationError || data.gacValidationError && 'uk-form-danger')} readOnly={data.busy} name='gac' value={data.gac} onChange={this.onGacChange} />
                    <button className='uk-button uk-button-primary uk-button-large uk-align-center uk-margin-top' disabled={data.busy || data.patternValidationError !== null || data.gacValidationError !== null} onClick={this.onSubmit}>Submit</button>
                </div>

                <GacErrorBox store={data} />
            </div>
        )
    }
}

