import * as React from 'react';
import * as classnames from 'classnames';

/******************************************************************************************************* */
// Fa
/******************************************************************************************************* */

export type TFaSize = 'xs' | 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x';
export type TFaPull = 'left' | 'right';
export type TFaRotate = '90' | '180' | '270';
export type TFaFlip = 'horizontal' | 'vertical';

export interface IFaProps extends React.HTMLAttributes<HTMLElement> {
    readonly faIcon: string;
    readonly faSize?: TFaSize;
    readonly faBorder?: boolean;
    readonly faFixedWidth?: boolean;
    readonly faSpin?: boolean;
    readonly faPull?: TFaPull;
    readonly faRotate?: TFaRotate;
    readonly faFlip?: TFaFlip;
    readonly faBrand?: boolean;
}

/**
 * Fa
 */
export class Fa extends React.Component<IFaProps, {}> {

    static uiPrefix: string = 'fas';

    static defaultProps: Partial<IFaProps> = {

    }

    static createElement(props: IFaProps) {
        return React.createElement(Fa, props);
    }

    render() {

        const { className, faBorder, faFixedWidth, faFlip, faIcon, faPull, faRotate, faSize, faSpin, faBrand, ...rest } = this.props;

        return React.createElement('i', {
            className: classnames(
                Fa.uiPrefix,
                faBorder && `${Fa.uiPrefix}-border`,
                faFixedWidth && `${Fa.uiPrefix}-fw`,
                faSpin && `${Fa.uiPrefix}-spin`,
                faSize && `fa-${faSize}`,
                faPull && `fa-${faPull}`,
                faRotate && `fa-${faRotate}`,
                faFlip && `fa-${faFlip}`,
                `fa-${faIcon}`,
                className
            ),
            ...rest
        })
    }
}