import * as React from 'react';
import { Icon } from './Icon';
import * as classnames from 'classnames';

// --

export interface ISearchProps extends React.InputHTMLAttributes<HTMLInputElement> {
    readonly keyword: string;
    readonly placeholder?: string;
    onSearch(keyword: string): void;
    onClear?(): void;
}

/**
 * Search
 */
export class Search extends React.Component<ISearchProps> {

    static defaultProps: Partial<ISearchProps> = {
        placeholder: 'Keyword...'
    }

    onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onSearch(ev.target.value);
    }

    onClear = (ev: React.MouseEvent) => {
        ev.preventDefault();
        this.props.onSearch && this.props.onSearch('');
    }

    render() {

        const { keyword, onSearch, onClear, className, ...rest } = this.props;

        return (
            <div className='uk-inline ui-search'>
                <form className='uk-form'>
                    <Icon iconType='search-icon' className='uk-form-icon' />
                    <input className={classnames('uk-input', className, onClear && 'ui-input-clear')} type='search' onChange={this.onChange} value={keyword} {...rest} />
                    {keyword && onClear ? <Icon iconType='close-icon' className='uk-form-icon uk-form-icon-flip' onClick={this.onClear} iconTagName='a' /> : null }
                </form>
            </div>
        )
    }
}