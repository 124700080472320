import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classnames from 'classnames';
import { Dropdown } from './Dropdown';
import { Image } from './Image';
import { Icon } from './Icon';
import { OffCanvas } from './OffCanvas';

/******************************************************************************************************* */
// Appbar
/******************************************************************************************************* */

const maxScroll = 200;

export interface IAppbarProps extends React.HTMLAttributes<HTMLDivElement> {
    appbarAppName: string;
    appbarUserMenuItems: React.ReactNode[],
    appbarAvatarSrc?: string;
    appbarUserName?: string;
    offcanvasContent?: React.ReactNode;
}

export interface IAppbarState {
    scroll: number;
    offCanvasActive: boolean;
}

/**
 * Appbar
 */
export class Appbar extends React.Component<IAppbarProps, {}> {

    static uiPrefix: string = 'ui-appbar';

    static createElement(props: IAppbarProps, ...children: React.ReactNode[]) {
        return React.createElement(Appbar, props, ...children);
    }

    static defaultProps: Partial<IAppbarProps> = {
        appbarAppName: 'App',
        appbarUserMenuItems: []
    }

    state: IAppbarState;

    constructor(props: IAppbarProps) {
        super(props);
        this.state = { scroll: window.scrollY, offCanvasActive: false }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
        window.addEventListener('click', this.onClick);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
        window.removeEventListener('click', this.onClick);
    }

    onScroll = () => {
        if (window.scrollY > maxScroll) {
            this.state.scroll !== maxScroll && this.setState({ scroll: maxScroll });
        }
        else this.setState({ scroll: window.scrollY });
    }

    onClick = (ev: MouseEvent) => {
        const target = ev.target as HTMLElement;
        if (target.hasAttribute('data-offcanvas') && target.getAttribute('data-offcanvas') === 'close') {
            this.onToggleOffCanvas();
        }
    }

    onToggleOffCanvas = () => {
        this.setState({ offCanvasActive: !this.state.offCanvasActive });
    }

    render() {
        const { className, appbarAppName, appbarUserMenuItems, appbarAvatarSrc, appbarUserName, offcanvasContent, children } = this.props;
        const { scroll } = this.state;

        const padding = 14 - Math.floor((scroll / maxScroll) * 12);

        const appbar = React.createElement('div', {
            key: '0',
            className: classnames(className, Appbar.uiPrefix, 'uk-light', scroll && 'ui-appbar-scroll'),
            style: { paddingTop: padding, paddingBottom: padding },
            'data-offcanvas': 'close'
        },
            React.createElement('div', {
                className: 'uk-container uk-container-expand'
            },
                React.createElement('div', { className: 'uk-grid uk-grid-collapse' },

                    // Left pane
                    React.createElement('div', { className: 'uk-width-auto uk-flex uk-flex-left uk-flex-middle' },
                        React.createElement('a', {
                            onClick: this.onToggleOffCanvas
                        },
                            offcanvasContent && React.createElement(Icon, { iconType: 'menu', className: 'uk-margin-small-right' }),
                        ),
                        React.createElement('span', { className: 'uk-logo' }, 
                            React.createElement('a', { href: '/home' }, appbarAppName)
                        )
                    ),

                    // Right pane
                    React.createElement('div', { className: 'uk-width-expand uk-flex uk-flex-right uk-flex-middle' },

                        children,

                        // User avatar
                        appbarAvatarSrc && Image.createElement({ className: 'uk-inline', imageAvatar: true, src: appbarAvatarSrc }),

                        // User menu
                        Dropdown.createElement({ dropdownMenuAlignment: 'right', className: 'uk-position-relative uk-inline ui-user-menu' },
                            React.createElement('button', { className: 'uk-button uk-button-text uk-button-small' },
                                appbarUserName
                            ),
                            React.createElement('ul', { className: 'uk-nav uk-dropdown-nav' },
                                ...appbarUserMenuItems
                            )
                        )
                    ),
                )
            )
        );

        return [ReactDOM.createPortal(appbar, document.body), React.createElement(OffCanvas, {
            key: '1',
            active: this.state.offCanvasActive,
            onClose: this.onToggleOffCanvas,
            'data-offcanvas': 'close'
        }, offcanvasContent)];
    }
}