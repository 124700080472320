// --

export interface IChildWidth<T> {
    '1-1': T;
    '1-2': T;
    '1-3': T;
    '1-4': T;
    '1-5': T;
    '1-6': T;
    'auto': T;
    'expand': T;

    '1-1@s': T;
    '1-2@s': T;
    '1-3@s': T;
    '1-4@s': T;
    '1-5@s': T;
    '1-6@s': T;
    'auto@s': T;
    'expand@s': T;

    '1-1@m': T;
    '1-2@m': T;
    '1-3@m': T;
    '1-4@m': T;
    '1-5@m': T;
    '1-6@m': T;
    'auto@m': T;
    'expand@m': T;

    '1-1@l': T;
    '1-2@l': T;
    '1-3@l': T;
    '1-4@l': T;
    '1-5@l': T;
    '1-6@l': T;
    'auto@l': T;
    'expand@l': T;

    '1-1@xl': T;
    '1-2@xl': T;
    '1-3@xl': T;
    '1-4@xl': T;
    '1-5@xl': T;
    '1-6@xl': T;
    'auto@xl': T;
    'expand@xl': T;
}

export const getChildWidthClasses = (childWidth: (keyof IChildWidth<any>)[]): string => {
    return childWidth.map(w => `ui-child-width-${w}`).join(' ');    
}

// --

export interface IFixedWidth<T> {
    'small': T;
    'medium': T;
    'large': T;
    'xlarge': T;
    'xxlarge': T;
    'auto': T;
    'expand': T;
    '1-1': T;
    '1-2': T;
    '1-3': T;
    '2-3': T;
    '1-4': T;
    '3-4': T;
    '1-5': T;
    '2-5': T;
    '3-5': T;
    '4-5': T;
    '5-5': T;
    '1-6': T;
    '5-6': T;
}

export interface IResponsiveWidth<T> {
    'small@s': T;
    'medium@s': T;
    'large@s': T;
    'xlarge@s': T;
    'xxlarge@s': T;
    'auto@s': T;
    'expand@s': T;
    '1-1@s': T;
    '1-2@s': T;
    '1-3@s': T;
    '2-3@s': T;
    '1-4@s': T;
    '3-4@s': T;
    '1-5@s': T;
    '2-5@s': T;
    '3-5@s': T;
    '4-5@s': T;
    '5-5@s': T;
    '1-6@s': T;
    '5-6@s': T;

    'small@m': T;
    'medium@m': T;
    'large@m': T;
    'xlarge@m': T;
    'xxlarge@m': T;
    'auto@m': T;
    'expand@m': T;
    '1-1@m': T;
    '1-2@m': T;
    '1-3@m': T;
    '2-3@m': T;
    '1-4@m': T;
    '3-4@m': T;
    '1-5@m': T;
    '2-5@m': T;
    '3-5@m': T;
    '4-5@m': T;
    '5-5@m': T;
    '1-6@m': T;
    '5-6@m': T;

    'small@l': T;
    'medium@l': T;
    'large@l': T;
    'xlarge@l': T;
    'xxlarge@l': T;
    'auto@l': T;
    'expand@l': T;
    '1-1@l': T;
    '1-2@l': T;
    '1-3@l': T;
    '2-3@l': T;
    '1-4@l': T;
    '3-4@l': T;
    '1-5@l': T;
    '2-5@l': T;
    '3-5@l': T;
    '4-5@l': T;
    '5-5@l': T;
    '1-6@l': T;
    '5-6@l': T;

    'small@xl': T;
    'medium@xl': T;
    'large@xl': T;
    'xlarge@xl': T;
    'xxlarge@xl': T;
    'auto@xl': T;
    'expand@xl': T;
    '1-1@xl': T;
    '1-2@xl': T;
    '1-3@xl': T;
    '2-3@xl': T;
    '1-4@xl': T;
    '3-4@xl': T;
    '1-5@xl': T;
    '2-5@xl': T;
    '3-5@xl': T;
    '4-5@xl': T;
    '5-5@xl': T;
    '1-6@xl': T;
    '5-6@xl': T;
}

// --

export const getWidthClasses = (width: (keyof IFixedWidth<any> | keyof IResponsiveWidth<any>)[]): string => {
    return width.map(w => `ui-width-${w}`).join(' ');
}