import * as React from 'react';
import { DataStore } from '../lib/store/DataStore';
import { Icon } from '../../../../uilib/dist/components/Icon';
import { observer } from 'mobx-react';

/**
 * GacErrorBox
 */
@observer
export class GacErrorBox extends React.Component<{ store: DataStore }> {

    render() {
        const { store } = this.props;

        if (!store.gacValidationError) return null

        return (
            <span className='uk-text-danger uk-text-large'>
                <Icon className='uk-margin-small-right' iconType='warning' iconTagName='span' />
                {store.gacValidationError}
            </span>
        )
    }
}

