import { BaseStore, IBaseStoreOpts } from '../../../../../lib/dist/store/BaseStore';
import { action, observable, runInAction, computed } from 'mobx';
import { validateCode } from '../api';
import { AppStore } from './AppStore';
import * as Bluebird from 'bluebird';

/**
 * DataStore
 */
export class DataStore extends BaseStore<AppStore> {

    public static createInstance(owner: AppStore) {
        return new DataStore(owner, {
            trackError: 'delegate'
        })
    }

    public asyncRequests: Bluebird<any>[];
    @observable public gac: string = '';
    @observable public patternValidationError: string | null = 'Too short';
    @observable public gacValidationError: string | null = null;

    public constructor(owner: AppStore, opts: Partial<IBaseStoreOpts>) {
        super(owner, opts);
        this.asyncRequests = observable.shallowArray([]);
    }

    protected init() {

    }

    @action public addAsyncRequest<T>(promise: Bluebird<T>): Bluebird<T> {
        this.asyncRequests.push(promise);

        promise.finally(() => {
            this.asyncRequests.splice(this.asyncRequests.indexOf(promise), 1);
        });

        return promise;
    }

    @computed public get hasPendingAsyncRequests() {
        return this.asyncRequests.length > 0;
    }

    @action public setGac(value: string) {
        if (value.length < 5) this.patternValidationError = 'Too short';
        else this.patternValidationError = null;
        this.gacValidationError = null;

        if (value.length <= 45) this.gac = value;
    }

    @action public validate() {
        if (this.patternValidationError !== null || this.gacValidationError !== null) return;
        this.busy = true;

        validateCode(this.gac)
            .then(data => runInAction('validation.done', () => {
                this.busy = false;
                if (typeof data === 'object' && data) {
                    if (typeof data.error === 'string') {
                        this.gacValidationError = data.error || 'Invalid code';
                    }
                    else if (typeof data.redirectUrl === 'string' && data.redirectUrl) {
                        // redirect to response
                        window.location.replace(data.redirectUrl);
                    }
                    else {
                        this.setError(new Error('Service error'));
                    }
                }
                else {
                    this.setError(new Error('Service error'));
                }

            }))
            .catch(err => runInAction('validation.fail', () => {
                this.busy = false;
                this.setError(new Error('Service error'));
            }))
    }
}