import * as React from 'react';
import { Dropdown } from './Dropdown';
import { Fa } from './Fa';
import * as classnames from 'classnames';
import { TCollectionColumns } from '../../../../common/base-collection';

// --

export interface ISortDropdownProps<A> extends React.HTMLAttributes<HTMLDivElement> {
    readonly sortDirection: 'asc' | 'desc';
    readonly sortField: keyof A;
    readonly sortColumns: TCollectionColumns<A>;
    readonly sortByHeader?: string;
    readonly sortDirectionHeader?: string;
    readonly sortButtonClasses?: any;
    readonly sortButtonLabel?: string;
    readonly sortButtonIcon?: string;
    onSort(field: string, direction: 'asc' | 'desc'): void;
}

/**
 * SortDropdown
 */
export class SortDropdown<A> extends React.Component<ISortDropdownProps<A>> {

    constructor(props: ISortDropdownProps<A>) {
        super(props);
        this.sort = this.sort.bind(this);
    }

    static defaultProps: Partial<ISortDropdownProps<any>> = {
        sortButtonIcon: 'sort-alpha-asc',
        sortButtonLabel: 'Sort',
        sortByHeader: 'Sort field',
        sortDirectionHeader: 'Direction',
        sortButtonClasses: 'uk-button-small uk-button-default'
    }

    sort(field?: keyof A, direction?: 'asc' | 'desc') {
        direction || (field ? this.props.sortDirection === 'asc' ? 'desc' : 'asc' : 'asc');
        field || (field = this.props.sortField);
        this.props.onSort(field as string, direction);
    }

    render() {

        const { sortByHeader, sortDirectionHeader, sortDirection, sortField, sortColumns, sortButtonIcon, sortButtonLabel, sortButtonClasses, onSort, ...rest } = this.props;

        return (
            <Dropdown {...rest}>
                <button className={classnames('uk-button', sortButtonClasses)}>
                    <Fa faIcon={sortButtonIcon} />  {sortButtonLabel}
                </button>

                <ul className='uk-nav uk-dropdown-nav'>
                    {sortByHeader && <li className='uk-nav-header'>{sortByHeader}</li>}

                    {sortColumns.filter(col => col.sortable !== false).map(col => {
                        return (<li key={col.fieldName as string} className={sortField === col.fieldName ? 'uk-active' : null}><a onClick={this.sort.bind(null, col.fieldName, null)} >{col.fieldLabel}</a></li>)
                    })}

                    {sortDirectionHeader && <li className='uk-nav-header'>{sortDirectionHeader}</li>}
                    <li className={sortDirection === 'asc' ? 'uk-active' : null} ><a onClick={this.sort.bind(null, null, 'asc')} >Asc</a></li>
                    <li className={sortDirection === 'desc' ? 'uk-active' : null}><a onClick={this.sort.bind(null, null, 'desc')} >Desc</a></li>

                </ul>
            </Dropdown>
        )
    }
}