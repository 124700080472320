import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as classnames from 'classnames';

// --

export interface IInputTooltipProps {
    readonly tooltipContent: string;
    readonly tooltipPosition?: 'top' | 'top-left' | 'top-right' | 'bottom' | 'bottom-left' | 'bottom-right' | 'left' | 'right';
    readonly tooltipMargin?: number;
}

export interface IInputTooltipState {
    active: boolean;
}

/**
 * InputTooltip
 */
export class InputTooltip extends React.Component<IInputTooltipProps> {

    state: IInputTooltipState;

    constructor(props: IInputTooltipProps) {
        super(props);
        this.state = { active: false };
    }

    static defaultProps: Partial<IInputTooltipProps> = {
        tooltipPosition: 'top',
        tooltipMargin: 6
    }

    target: Element;
    tooltip: Element;

    componentDidMount() {
        this.target = ReactDOM.findDOMNode(this.refs['target']) as Element;
        this.tooltip = ReactDOM.findDOMNode(this.refs['tooltip']) as Element;
    }

    componentWillReceiveProps(props: IInputTooltipProps) {
        if (!props.tooltipContent) {
            this.setState({ active: false });
        }
        else this.setState({ active: true });
    }

    componentDidUpdate(prevProps: IInputTooltipProps, prevState: IInputTooltipState) {
        if (!prevState.active && this.state.active) {
            this.forceUpdate();
        }
    }

    render() {

        const { children, tooltipContent, tooltipPosition, tooltipMargin } = this.props;
        const { active } = this.state;

        if (!children) return children;

        let targetRect, tooltipRect: ClientRect;
        let left: number = null;
        let top: number = null;
        let bottom: number = null;
        let right: number = null;

        if (active) {
            targetRect = this.target.getBoundingClientRect();
            tooltipRect = this.tooltip.getBoundingClientRect();

            switch (tooltipPosition) {
                case 'top-left':
                    top = targetRect.top - tooltipRect.height - tooltipMargin + window.scrollY;
                    left = targetRect.left + window.scrollX;
                    break;

                case 'top-right':
                    top = targetRect.top - tooltipRect.height - tooltipMargin + window.scrollY;
                    left = targetRect.left + (targetRect.width - tooltipRect.width) + window.scrollX;
                    break;

                case 'top':
                    top = targetRect.top - tooltipRect.height - tooltipMargin + window.scrollY;
                    left = targetRect.left + (targetRect.width - tooltipRect.width) / 2 + window.scrollX;
                    break;

                case 'bottom-left':
                    top = targetRect.top + tooltipRect.height + tooltipMargin + window.scrollY;
                    left = targetRect.left + window.scrollX;
                    break;

                case 'bottom-right':
                    top = targetRect.top + tooltipRect.height + tooltipMargin + window.scrollY;
                    left = targetRect.left + (targetRect.width - tooltipRect.width) + window.scrollX;
                    break;

                case 'bottom':
                    top = targetRect.top + tooltipRect.height + tooltipMargin + window.scrollY;
                    left = targetRect.left + (targetRect.width - tooltipRect.width) / 2 + window.scrollX;
                    break;

                case 'left':
                    top = targetRect.top + (targetRect.height - tooltipRect.height) / 2 + window.scrollY;
                    left = targetRect.left - tooltipRect.width - tooltipMargin + window.scrollX;
                    break;

                case 'right':
                    top = targetRect.top + (targetRect.height - tooltipRect.height) / 2 + window.scrollY;
                    left = targetRect.left + targetRect.width + tooltipMargin + window.scrollX;
                    break;
            }

        }

        return ([
            ReactDOM.createPortal(
                <div
                    style={{ left, top, bottom, right }}
                    ref='tooltip'
                    className={classnames('uk-tooltip', active && tooltipContent && 'uk-active')}
                    key='0'>{tooltipContent}
                </div>,
                document.body),
            React.cloneElement(React.Children.only(React.isValidElement(children) ? children : <div />), {
                ref: 'target',
                key: '1'
            })
        ])
    }
}