import * as React from 'react';
import * as classnames from 'classnames';
import { Tag } from './Tag';

// --

export interface ITagsProps extends React.HTMLAttributes<HTMLDivElement> {
    onSearch(term: string): void;
    onTagClose(value: string): void;
    tags: { value: string, label: string }[];
    tagClassName?: string;
    readOnly?: boolean;
}

/**
 * Tags
 */
export class Tags extends React.Component<ITagsProps> {

    static uiPrefix = 'ui-tags uk-flex uk-flex-left uk-flex-row uk-flex-wrap';

    render() {

        const { onSearch, onTagClose, tags, className, tagClassName, readOnly, children, ...rest } = this.props;

        return (
            <div className={classnames(Tags.uiPrefix, className)} {...rest}>
                {tags.map(tag => (
                    <Tag onClose={onTagClose.bind(null, tag.value)} key={tag.value} className={tagClassName} readOnly={readOnly}>{tag.label}</Tag>
                ))}

                {children}
            </div>
        )
    }

}