import { observable, computed, runInAction, IObservableValue, IObservableObject } from 'mobx';
import { IError, IErrorable } from '../../../../common';

// --

export interface IBaseStore<O> {
    readonly owner: O;
    readonly busy: boolean | IObservableValue<boolean>;
    readonly error: IError | IObservableObject;
}

export interface IBaseStoreOpts {
    trackError?: boolean | 'delegate';
}

/**
 * BaseStore class
 */
export abstract class BaseStore<O> implements IBaseStore<O>, IErrorable {

    protected opts: Partial<IBaseStoreOpts>;
    @observable public busy: boolean = false;
    public error: IError;

    constructor(public owner: O, opts: Partial<IBaseStoreOpts>) {

        if (typeof opts.trackError === 'undefined') opts.trackError = true;
        if (opts.trackError === true) this.error = observable({ name: null, message: null });
        else if (opts.trackError === 'delegate') { }
        else this.error = { name: null, message: null };

        this.opts = opts;

        this.init();
    }

    public setBusy(busy: boolean) {
        runInAction('setBusy', () => {
            this.busy = busy;
        })

        return this;
    };

    public setError(err: IError = { name: null, message: null }) {
        if (this.opts.trackError === 'delegate' && this.owner instanceof BaseStore) {
            console.log('Delegating error', this.constructor.name)
            this.owner.setError(err);
        }
        else if (this.opts.trackError === true) {
            console.log('Setting error', this.constructor.name)
            runInAction('setError', () => {
                this.error.name = err ? err.name : null;
                this.error.message = err ? err.message : null;
            })
        }

        return this;
    };

    public clearError() {
        runInAction('clearError', () => {
            this.error.name = null;
            this.error.message = null;
        })
    }

    @computed public get hasError() { return Boolean(this.error.name || this.error.message); };

    protected abstract init(): void;
}

