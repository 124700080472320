import * as React from 'react';
import { Pagination } from './Pagination';
import { observer } from 'mobx-react';
import { IBaseCollectionFeatures } from '../../../../common';

// --

export interface ITablePaginationProps<A> {
    readonly features: IBaseCollectionFeatures<A>;
    onPageChange(index: number): void;
}

/**
 * Observer wrapper for Pagination component
 */
@observer
export class TablePagination<A> extends React.Component<ITablePaginationProps<A>> {

    render() {

        const { props } = this;

        return (
            <div className='uk-flex uk-flex-right uk-flex-middle'>
                <div className='uk-margin-right uk-text-small'>
                    <span className='uk-text-muted uk-margin-small-right'>Items:</span>
                    <span className="uk-label">{props.features.itemCount || 0}</span>
                </div>

                <div className='uk-margin-right uk-text-small'>
                    <span className='uk-text-muted uk-margin-small-right'>Page:</span>
                    <span className="uk-label">{props.features.pageIndex + 1} / {props.features.pageCount}</span>
                </div>

                <Pagination
                    paginationPageCount={props.features.pageCount}
                    paginationPageIndex={props.features.pageIndex}
                    paginationOnPageChange={props.onPageChange}
                />
            </div>
        )
    }
}