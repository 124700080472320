import * as React from 'react';
import * as PropTypes from 'prop-types';
import { AppStore, } from './store';

// --

export interface IApplicationContext {
    store: AppStore,
}

export const ApplicationContext = {
    store: PropTypes.object,
}

export const AppContext = React.createContext({
    store: null
})